<template id="main">
  <div id="app">
    <vue-particles
      class="particles"
      color="#dedede"
      :particleOpacity="0.7"
      :particlesNumber="60"
      shapeType="circle"
      :particleSize="4"
      linesColor="#dedede"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    ></vue-particles>

    <div id="content">
      <IntroComponent class="comp" id="intro-comp"></IntroComponent>
      <TechsComponent class="comp" id="techs-comp"></TechsComponent>
      <ContactComponent class="comp" id="contact-comp"></ContactComponent>
    </div>

    <footer id="footer">
      <div class="footer-center">
        <CopyrightComponent></CopyrightComponent>
      </div>
      <div class="footer-right">
        <LocaleSwitcher></LocaleSwitcher>
      </div>
    </footer>
  </div>
</template>

<script>
import IntroComponent from './components/IntroComponent.vue';
import ContactComponent from './components/ContactComponent.vue';
import LocaleSwitcher from './components/LocaleSwitcher.vue';
import CopyrightComponent from './components/CopyrightComponent.vue';
import TechsComponent from './components/TechsComponent.vue';

export default {
  name: 'App',
  mounted() {
    document.title = "Portfolio G. RONNEL";
  },
  components: {
    IntroComponent,
    ContactComponent,
    LocaleSwitcher,
    CopyrightComponent,
    TechsComponent
  }
}
</script>

<style>
  body, html {
    margin: 0;
    height: 100%; 
    background-color: rgba(29, 23, 22, 0.95);
  }

  #app {
    position: relative; 
    height: calc(100vh - 60px); 
    overflow: hidden;
  }

  .particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    z-index: 0;
  }

  #content {
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    z-index: 1;
  }

  .comp {
    min-height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    z-index: 1;
  }


  #footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(29, 23, 22, 1);
    z-index: 1000;
    padding: 0 20px;
  }

  .footer-center {
    flex: 1; 
    margin-left: 60px;
    text-align: center; 
  }

  .footer-right {
    margin-right: 30px;
  }
</style>
