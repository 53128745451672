<template>
  <footer class="copyright-footer">
    <p>&copy; {{ currentYear }} Guillaume RONNEL</p>
  </footer>
</template>

<script>
  export default {
    name: 'CopyrightComponent',
    data() {
      return {
        currentYear: new Date().getFullYear()
      };
    }
  }
</script>

<style scoped>
  .copyright-footer {
    text-align: center;
    padding: 20px;
    color: #f1f1f1;
    font-size: 14px;
  }

  .copyright-footer p {
    margin: 0;
  }
</style>
