<template>
  <div>
    <div id="intro-page">
      <div id="intro-header">
        <Glitch id="name" subtle>
          Guillaume RONNEL
        </Glitch>
      </div>
      <h5>{{ $t('intro') }}</h5>
      <div id="socials">
        <a href="https://github.com/pvnkrockjesvs" target="_blank">
          <img src="../assets/icons/github.svg" width="24" height="24" class="icon-socials" />
        </a>
        <a href="https://gitlab.com/pvnkrockjesvs" target="_blank">
          <img src="../assets/icons/gitlab.svg" width="24" height="24" class="icon-socials" />
        </a>
        <a href="https://www.linkedin.com/in/guillaumeronnel/" target="_blank">
          <img src="../assets/icons/linkedin.svg" width="24" height="24" class="icon-socials" />
        </a>
        <a href="https://www.malt.fr/profile/guillaumeronnel" target="_blank">
          <img src="../assets/icons/malt.png" width="24" height="24" class="icon-socials" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Glitch } from 'vue-glitched'

export default {
  components: {
    Glitch
  }
}
</script>

<style>
  #intro-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    padding: 20px; 
    text-align: center;
  }

  #intro-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 40px;
  }

  #name {
    font-size: 100px;
    font-weight: 700;
    color: var(--glitch-global-fg);
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); 
    transition: transform 0.3s; 
    margin-bottom: 20px;
  }

  #name:hover {
    transform: scale(1.05);
  }

  h5 {
    font-size: 25px;
    font-weight: 300;
    margin: 0 0 10px 0;
    color: #ffffff;
    opacity: 80%;
  }

  .icon-socials {
    margin-top: 5px;
    margin-right: 7px;
    transition: transform 0.3s, filter 0.3s;
  }

  #socials a:last-child .icon-socials {
    margin-right: 0;
  }

  .icon-socials:hover {
    transform: scale(1.2); 
    filter: brightness(1.2); 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  :root {
    --glitch-global-bg: rgba(29, 23, 22, 0.5);
    --glitch-global-fg: #ffffff;
    --glitch-global-fga: #076678;
    --glitch-global-fgb: #cc241d;
  }
</style>
