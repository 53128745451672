<template>
  <div class="tech-section">
    <div class="stack-title-container">
      <h1 class="stack-title">{{ $t('stack.mystack') }}</h1>
    </div>
    
    <div class="tech-grid">
      <div class="tech-category">
        <h2 class="tech-subtitle">
          <i class="fas fa-code"></i> Front End
        </h2>
        <ul class="tech-list">
          <li><img src="@/assets/icons/vuejs.svg" alt="VueJS" class="tech-icon" /></li>
          <li><img src="@/assets/icons/react.svg" alt="ReactJS" class="tech-icon" /></li>
          <li><img src="@/assets/icons/typescript.svg" alt="Typescript" class="tech-icon" /></li>
          <li><img src="@/assets/icons/html5.svg" alt="HTML" class="tech-icon" /></li>
        </ul>
      </div>

      <div class="tech-category">
        <h2 class="tech-subtitle">
          <i class="fas fa-cogs"></i> Back End
        </h2>
        <ul class="tech-list">
          <li><img src="@/assets/icons/nodejs.svg" alt="NodeJS" class="tech-icon" /></li>
          <li><img src="@/assets/icons/puppeteer.svg" alt="Puppeteer" class="tech-icon" /></li>
          <li><img src="@/assets/icons/python.svg" alt="Python" class="tech-icon" /></li>
          <li><img src="@/assets/icons/bash.svg" alt="Bash" class="tech-icon" /></li>
        </ul>
      </div>

      <div class="tech-category">
        <h2 class="tech-subtitle">
          <i class="fas fa-database"></i> {{ $t('stack.database')}}
        </h2>
        <ul class="tech-list">
          <li><img src="@/assets/icons/mysql.svg" alt="MySQL" class="tech-icon" /></li>
          <li><img src="@/assets/icons/mongodb.svg" alt="MongoDB" class="tech-icon" /></li>
          <li><img src="@/assets/icons/postman.svg" alt="Postman" class="tech-icon" /></li>
        </ul>
      </div>

      <div class="tech-category">
        <h2 class="tech-subtitle">
          <i class="fas fa-laptop-code"></i> {{ $t('stack.otherstack') }}
        </h2>
        <ul class="tech-list">
          <li><img src="@/assets/icons/linux.svg" alt="Linux" class="tech-icon" /></li>
          <li><img src="@/assets/icons/opensuse.svg" alt="Opensuse" class="tech-icon" /></li>
          <li><img src="@/assets/icons/debian.svg" alt="Debian" class="tech-icon" /></li>
          <li><img src="@/assets/icons/docker.svg" alt="Docker" class="tech-icon" /></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TechComponent',
  mounted() {
    this.$i18n.setLocaleMessage('en', {
      techstack: 'Technical Stack',
    });
  }
};
</script>

<style scoped>
  .tech-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .stack-title-container {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .stack-title {
    font-size: 48px;
    color: #ffffff;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  }

  .tech-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    width: 100%;
    max-width: 1200px;
  }

  .tech-category {
    background-color: rgba(33, 33, 33, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease, background-color 0.3s ease;
  }

  .tech-category:hover {
    transform: translateY(-10px);
    background-color: rgba(33, 33, 33, 0.9);
  }

  .tech-subtitle {
    font-size: 24px;
    margin-bottom: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tech-subtitle i {
    margin-right: 10px;
    color: #ffffff;
  }

  .tech-list {
    list-style-type: none;
    padding: 0;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tech-list li {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tech-icon {
    width: 60px;
    height: 60px;
    transition: transform 0.2s ease, filter 0.2s ease;
    filter: grayscale(80%); 
  }

  .tech-list li:hover .tech-icon {
    transform: scale(1.1); 
    filter: grayscale(0);
  }
</style>
