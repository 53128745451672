import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import { Glitch } from 'vue-glitched';
import VueParticles from 'vue-particles'; // Import VueParticles

import EN from './locales/en.json';
import FR from './locales/fr.json';

// Fonction pour détecter la langue du navigateur et choisir la locale
function getBrowserLocale(fallbackLocale = 'EN') {
  const browserLocale = navigator.language || navigator.userLanguage; // Obtenir la langue du navigateur
  const availableLocales = ['EN', 'FR']; // Tes locales disponibles

  // Extraire uniquement le code de langue (ex: "en" de "en-US")
  const languageCode = browserLocale.split('-')[0].toUpperCase();

  // Si la langue du navigateur est disponible dans les locales, retourne-la. Sinon, utilise la fallbackLocale
  return availableLocales.includes(languageCode) ? languageCode : fallbackLocale;
}

// Crée une instance de vue-i18n
const i18n = createI18n({
  locale: getBrowserLocale('EN'), // Détecte automatiquement la langue du navigateur
  fallbackLocale: 'EN',
  messages: {
    EN,
    FR 
  }
});

// Création de l'application Vue
createApp(App)
  .use(i18n)
  .use(VueParticles) // Use VueParticles
  .component('Glitch', Glitch)
  .mount('#app');
