<template>
  <select id="select-locale" v-model="$i18n.locale">
   <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
    {{ locale }}
   </option>
  </select>
</template>
<script>
  export default {
    name: "LocaleSwitcher",
    data() {
      return { locales: ["FR", "EN"] };
    }
  };
 </script>

<style>
#select-locale {
  margin: 10px;
  padding: 10px 15px;
  background-color: #1d1716;
  color: #f1f1f1;
  border: 1px solid #444;
  border-radius: 25px;
  font-size: 16px;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

#select-locale:hover {
  background-color: #2d2d2d;
  color: #fff;
  border-color: #00b4d8;
}

#select-locale:focus {
  border-color: #00b4d8;
  box-shadow: 0 0 10px #00b4d8;
}

#select-locale option {
  background-color: #1d1716;
  color: #f1f1f1;
}




</style>