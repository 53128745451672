<template>
  <div id="contacts">
    <div class="contact-card">
      <h1 class="contact-title">{{ currentText }}</h1>
      <a class="contact-email" :href="mailtoLink" target="_blank">{{ $t('contactme') }}</a>
    </div>
  </div>
</template>

<script>  
  export default {
    data() {
      return {
        textKeys: [
          'projects.project',
          'projects.quote',
          'projects.collab'
        ],
        currentIndex: 0, 
        intervalId: null,
        email: 'ronnel.portfolio@proton.me',
        subject: 'Contact portfolio: ',
      };
    },
    computed: {
      currentText() {
        return this.$t(this.textKeys[this.currentIndex]);
      },
      mailtoLink() {
        return `mailto:${this.email}?subject=${encodeURIComponent(this.subject)}`;
      }
    },
    mounted() {
      this.startTextRotation();
    },
    beforeUnmount() {
      clearInterval(this.intervalId);
    },
    methods: {
      startTextRotation() {
        this.intervalId = setInterval(() => {
          this.currentIndex = (this.currentIndex + 1) % this.textKeys.length;
        }, 1500);
      }
    }
  };
</script>

<style scoped>
  #contacts {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
  }

  .contact-card {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    text-align: center;
  }

  .contact-title {
    color: #ffffff;
    font-size: 50px;
    margin-bottom: 40px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7);
  }

  .contact-email {
    display: inline-block;
    color: #ffffff;
    font-size: 18px;
    padding: 12px 24px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
  }

  .contact-email:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
  }
</style>
